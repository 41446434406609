<template>
  <div>
    <CRow>
      <CCol sm="12">
        <div style="float: right; font-size: 12px;">
          <table>
            <tr>
              <td>
                  <label style="margin-bottom: 6px;"> Fecha:</label>
                  <v-date-picker                    
                    v-model="range"    
                    class="date-picker"
                    locale="es-MX" size="sm"          
                    mode="range"
                    style="width: 210px; font-size: 12px !important;"
                  />  
              </td>
              <td>
                <label>Dirección: </label>
                <CSelect
                    :value.sync="type_id"
                    :options="typesOptions"
                    invalidFeedback="Este campo es requerido."
                    style="width: 210px; font-size: 12px !important; margin-top: -5px; margin-bottom: 0"
                    @change="changeFilters"
                  />
              </td>
            </tr>
          </table>
        </div>
        <br /><br /><br />
        <div id="map" :style="'border: 1px solid; width: 100%; height: '+(height - 360)+'px;'"></div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import supports from '../../services/supports';
import store from '../../store'
import { Loader } from '@googlemaps/js-api-loader';
import { DatePicker } from 'v-calendar'
import moment from 'moment';
import moment_timezone from 'moment-timezone';

moment.tz.setDefault('America/Mexico_city');

export default {
  name: 'BinnacleMap',
  components: { 'v-date-picker': DatePicker },
  data: function () {
		return {
            latitude: 0,
            longitude: 0,
            height: 1000,
            account: false,
            supports: [],
            type_id: '',
            types: [],
            typesOptions: [],
            range: {
              start: new moment().startOf('year').toDate(),
              end: new moment().endOf("year").toDate(),
            }
        }
  },
  mounted: async function() {
    this.loading();

    this.latitude = localStorage.getItem('latitude')*1;
    this.longitude = localStorage.getItem('longitude')*1;

    let response = await supports.get();

    if(response.type == "success"){
      this.supports = response.data; 
    }

    let response2 = await supports.getTypes();

    if(response2.type == "success"){
      this.types = response2.data;

      this.typesOptions = await this.$parseSelectOptions(this.types, "id", "name", [{"label": "Todos","value":""}]);
    }

    this.account = JSON.parse(localStorage.getItem("account"));
    this.latitude = this.account.latitude*1;
    this.longitude = this.account.longitude*1;

    this.loadMap();
    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.users = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    },
    loadMap(){
      const apiOptions = {
        apiKey: "AIzaSyBjRSxI62T4CTN4Jl_R-1PJoIl2cAo2pAA"
      }

      this.height = window.screen.height;

      const loader = new Loader(apiOptions);

      loader.load().then(() => {
        const map = this.displayMap();
      });
    },
    displayMap: async function() {
			const mapOptions = {
				center: { lat: this.latitude, lng: this.longitude },
				zoom: 15,
        mapTypeId: 'satellite',
        mapId: "434879d77db0d24b"
			};

      this.loading();

      const mapDiv = document.getElementById('map');
			const map = new google.maps.Map(mapDiv, mapOptions);

      this.supports.forEach(async (support) => {
        let geocoder = new google.maps.Geocoder();

          let _this = this;

          geocoder.geocode( { 'address': support.geolocation+" "+_this.account.name+" Jalisco"}, function(results, status) {
            if (status == 'OK') {
              _this.placeMarker(map, results[0].geometry.location, support);
            } else {}
          });
      });

      this.loaded();

			return map;
		},
    placeMarker: async function(map, location, support) {
      const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");
      const { InfoWindow } = await google.maps.importLibrary("maps");

      let content = 
      '<div style="width: 200px;">'+
      '<b>No. Evento: </b>'+support.number+'<hr />'+
      '<b>Fecha: </b>'+support.date+'<br />'+
      '<br /><b>Descripción: </b>'+support.description+'<br />'+
      (support.geolocation ? '<br /><br /><b>Domicilio: </b>'+support.geolocation+'' : '')+
      '<br /><hr /><div style="width: 100%; text-align: center;"><a href="/#/supports/'+support.code+'/view">Ver Evento</a><br /></div>'+
      '</div>'+
      ''
      ;

      const infowindow = new google.maps.InfoWindow({
        content: content,
        headerDisabled: true
      });

      infowindow.close();

      let color = "white";
      switch(support.type_code){
          case 'program': color = "#00FF00"; break;
          case 'support': color = "#FFFF00"; break;
          case 'action': color = "#0000FF"; break;
      }

      const pinBackground = await this.$pin(color);

      const marker = new AdvancedMarkerElement({
        map,
        position: location,
        content: pinBackground.element,
        gmpClickable: true,
      });

      marker.addListener("click", ({ domEvent, latLng }) => {
        if(infowindow.isOpen){
          infowindow.close();
        }
        else{
          infowindow.open({
            anchor: marker,
            map,
          });
        }
      });
    },
    changeFilters: async function(){
      this.loading();
      let dates = [moment(this.range.start).format("YYYY-MM-DD"),moment(this.range.end).format("YYYY-MM-DD")];

      let response = await tickets.getFilteredTickets(dates, this.direction_code, this.stage_code);

      if(response.type == "success"){
        this.tickets = response.data;
        this.loadMap();
      }

      this.loaded();
    },
  },
  watch: {
    range: async function(){
      await this.changeFilters();
    }
  },
}
</script>
<style>
  .date-picker input{
    height: 32px;
    margin-top: -3px;
    font-size: 12px !important;
  }

  select{
    height: 32px;
    font-size: 12px !important;
    margin-bottom: 0px;
  }
</style>